import { Suspense, useState } from "react";
import { NextPage } from "next";
import { HomepageComponent, Header2, Footer2 } from "@components";
import Head from "next/head";
import { Box, IconButton, Spinner, useColorMode, useColorModeValue, useToast } from "@chakra-ui/react";
import Image from "next/image";
import { setColorMode } from "@redux/actions";
import { Sunny, Moon } from "react-ionicons";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { IUser } from "@redux/slices/user";

const HomePage: NextPage = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const dispatch = useDispatch();
    const { colorMode, toggleColorMode } = useColorMode();
    const toast = useToast();
    const router = useRouter();
    const user = useSelector((state: { user: IUser }) => state.user);

    return (
        <>
            <Head>
                <title>RoboEpics | ربات‌نامه</title>
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            </Head>
            <div className="overflow-x-hidden">
                <Box
                    id="loading"
                    sx={{
                        position: "fixed",
                        display: isLoaded ? "none" : "block",
                        width: "100%",
                        height: "100%",
                        top: "0",
                        left: "0",
                        textAlign: "center",
                        zIndex: 9999,
                    }}
                    backgroundColor={useColorModeValue("#f7f7f8", "#14181d")}
                >
                    <IconButton
                        variant="ghost"
                        size="md"
                        aria-label="گزینه ها"
                        onClick={() => {
                            dispatch(setColorMode(colorMode == "light" ? "dark" : "light"));
                            toggleColorMode();
                        }}
                        icon={useColorModeValue(<Moon height="18px" width="18px" />, <Sunny color="#fff" height="18px" width="18px" />)}
                        top={"2.5%"}
                        left="44.0%"
                    />
                    <Box
                        className={`animation flex mx-auto`}
                        w="200px"
                        h="200px"
                        mt={20}
                        style={useColorModeValue(
                            {
                                animation: "spin 15s linear infinite",
                                MozAnimation: "spin 15s linear infinite",
                                WebkitAnimation: "spin 15s linear infinite",
                            },
                            {
                                animation: "spin 15s linear infinite",
                                MozAnimation: "spin 15s linear infinite",
                                WebkitAnimation: "spin 15s linear infinite",
                                filter: "invert(1)",
                                WebkitFilter: "invert(1)",
                            },
                        )}
                    >
                        <Image
                            src={useColorModeValue("/images/logo-02-square.png", "/images/logo-06-square.png")}
                            layout={"fill"}
                            objectFit="cover"
                            quality={100}
                        />
                    </Box>
                    <Box
                        style={useColorModeValue(
                            {},
                            {
                                filter: "invert(1)",
                                WebkitFilter: "invert(1)",
                            },
                        )}
                        mt={8}
                    >
                        <Image
                            src={"/images/logo-01.png"}
                            layout={"intrinsic"}
                            objectFit="cover"
                            width={"300"}
                            height={"150"}
                            quality={100}
                        />
                    </Box>
                </Box>

                <Suspense fallback={<Spinner size="xl" />}>
                    {isLoaded && <Header2 toast={toast} router={router} user={user} />}
                    <div id="__content" style={{ display: isLoaded ? "initial" : "none" }} className="overflow-x-hidden">
                        <div
                            className="flex flex-col justify-start items-center w-full h-full m-auto overflow-x-hidden"
                            style={{ maxWidth: "1150px" }}
                        >
                            <HomepageComponent setIsLoaded={setIsLoaded} toast={toast} router={router} />
                        </div>
                    </div>
                    <Footer2 />
                </Suspense>
            </div>
        </>
    );
};

export default HomePage;
